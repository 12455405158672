.shift__container {
  background-color: rgb(238, 238, 238);
  padding: 10px;
  margin-bottom: 20px;
}
.shift__info {
  display: flex;
  margin-top: -14px;
  /* justify-content: space-between; */
}
.add__btn {
  float: right;
  margin-bottom: 10px;
}
.shifts__add {
  display: flex;
  justify-content: space-between;
}
