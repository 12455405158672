html,
body {
  /* để 1rem là 10px */
  font-size: 62.5%;
  position: relative;
}

html *,
body * {
  font-family: var(--font-family);
}

/* override antd style */
.ant-menu {
  background: transparent !important;
}

.ant-menu-submenu-popup {
  background: #001529 !important;
}

.ant-menu-item-selected {
  background-color: white !important;
  color: var(--color-secondary) !important;
}

.ant-menu-item-selected > * {
  color: var(--color-secondary) !important;
}

/* antd MenuItem */
.ant-menu-inline-collapsed {
  width: 50px !important;
}

.ant-layout-footer {
  background: white !important;
  padding: 1.6rem 5rem !important;
}

.ant-page-header {
  padding-right: 0 !important;
  padding-left: 0 !important;
  padding-top: 0 !important;
}

/* override style for filter on antd table */
.ant-dropdown-menu-item {
  display: flex;
  align-items: center;
}

/* Modal for edit availability in Preference page */
.custom-modal .ant-modal-header {
  text-align: center;
  padding: 2.4rem 0;
  margin: 0 3rem;
  font-size: 2rem;
}

.custom-modal .ant-modal-title {
  font-size: 2rem;
}

/* global button */
.custom-button {
  border-radius: 6px !important;
  background: var(--color-secondary) !important;
  font-size: 1.4rem !important;
}

.primary-button {
  border-radius: 4.4rem;
  background-color: var(--color-secondary);
  font-size: 1.7rem;
  padding: 1rem 4rem;
  color: white;
  box-shadow: none;
  border: none;
  outline: none;
  font-weight: 400;
  cursor: pointer;
}

.custom-button[disabled] {
  color: white !important;
  opacity: 0.5;
}

/* language switcher */
.language-flag {
  width: 3rem;
  height: 2rem;
  margin-right: 1.2rem;
}

/* full calendar */
.fc {
  height: 80rem;
}

/* make calendar scrollable */
.fc-view {
  overflow-x: auto;
}

.fc-view > table {
  min-width: 100%;
  width: 150rem;
}

.fc-col-header-cell-cushion {
  color: var(--color-text);
  font-weight: 500;
  font-size: 1.5rem;
}

.fc-toolbar-title {
  color: var(--color-text);
  font-weight: 500;
  font-size: 2rem;
}

.fc-h-event,
.fc-v-event {
  background-color: transparent;
  border: none;
}

.fc-daygrid-dot-event:hover {
  background: transparent;
}

/* ag-grid bug reference: https://stackoverflow.com/questions/52767610/how-to-always-show-the-horizontal-scroll-bar-in-ag-grid */
.ag-body-viewport.ag-layout-normal {
  overflow-x: scroll !important;
}

:root {
  --font-family: 'Open Sans', 'Montserrat', 'Helvetica', 'Arial', 'sans-serif';
  --font-family-secondary: 'Hachi Maru Pop', 'cursive';
  --color-text: #2a2a2a;
  --color-text-gray: #cdcbd6;
  --color-primary: #25a9e3;
  --color-secondary: #243d93;
  --color-light-blue: #2e57e6;
  --color-dark-blue: #1b365d;
  --color-ternary: #f5d782;
  --color-border-blue: #abd2ff;
  --color-border-gray: #ebebeb;
  --color-border-input: #767a7c;
  --color-border: #cccccc;
  --color-red: #cc1202;
  --color-gray: #e4e4e4;
  --color-dark-green: #12c235;
  --color-green: #9acea7;
  --color-light-green: #ebfbef;
  --color-purple: #8212c2;
  --color-orange-dark: #ff9646;
  --color-orange-light: #ffc145;
  --color-text-light-gray: #dbdbdb;
  --color-sidebar: #bdc7c9;
  --color-black: #1b1a17;
  --color-pink: #eca3f5;
  --box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);
}
